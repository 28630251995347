<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <Form :rows="rows" v-model="detail" />
    <FooterButton
      v-show="hideshow"
      cancelLabel="重置"
      confirmLabel="确认"
      @cancel="reset"
      @confirm="confirm"
    />
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterDoubleButton.vue";
import Form from "@/components/common/form/index.vue";
import dayjs from "dayjs";
export default {
  components: {
    FooterButton,
    Header,
    Form,
  },
  data() {
    return {
      hideshow: true,
      docmHeight: document.documentElement.clientHeight,  //默认屏幕高度
      showHeight: document.documentElement.clientHeight,   //实时屏幕高度

      detail: {},
      rows: [
        {
          key: "modelFieldType",
          label: "示范田类型",
          type: "picker",
          dictType: "modelfield_type",
        },
        {
          key: "modelFieldStatus",
          label: "示范田状态",
          type: "radio",
          data: [
            { key: "0", value: "示范中" },
            { key: "1", value: "已结束" },
          ],
        },
        { key: "createTime1", label: "创建开始时间", type: "date" },
        { key: "createTime2", label: "创建结束时间", type: "date" },
        {
          key: "plantCover",
          label: "示范作物",
          type: "picker",
          gap: true,
          dictType: "plantSelect",
        },
        { key: "visitTimes", label: "拜访次数", type: "range", unit: "次" },
        { key: "meetingNum", label: "会议次数", type: "range", unit: "次" },
        { key: "plantNum", label: "示范亩数", type: "range", unit: "亩" },
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = ()=>{
      return(()=>{
        this.showHeight = document.body.clientHeight;
      })()
    }
  },
  //监听
  watch:{
    showHeight:function() {
      if(this.docmHeight > this.showHeight){
        this.hideshow=false
      }else{
        this.hideshow=true
      }
    }
  },
  activated() {
    if (!window.localStorage.getItem("shidanli-filter")) {
      this.detail = {};
    }
  },
  methods: {
    reset() {
      this.detail = {};
      // this.$emit("input", {});
    },
    confirm() {
      const {
        modelFieldType,
        modelFieldStatus,
        createTime1,
        createTime2,
        plantCover,
        visitTimes,
        meetingNum,
        plantNum,
      } = this.detail;
      const query = {
        modelFieldType,
        modelFieldStatus,
        createTime1: createTime1 ? dayjs(createTime1).format("YYYY-MM-DD") : "",
        createTime2: createTime2 ? dayjs(createTime2).format("YYYY-MM-DD") : "",
        plantCover: plantCover || "",
        visitTimes1: visitTimes ? visitTimes[0] : "",
        visitTimes2: visitTimes ? visitTimes[1] : "",
        meetingNum1: meetingNum ? meetingNum[0] : "",
        meetingNum2: meetingNum ? meetingNum[1] : "",
        plantNum1: plantNum ? plantNum[0] : "",
        plantNum2: plantNum ? plantNum[1] : "",
      };
      // this.$emit("input", query);
      // this.$emit("confirm");
      window.localStorage.setItem(
        "shidanli-filter",
        JSON.stringify(query || {})
      );
      this.$router.push({ path: "/demonstrationField" });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
