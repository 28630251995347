<template>
  <div class="demonstration-field-basic">
    <Form
      ref="form"
      :rows="
        activeName === 'DemonstrationFieldShidanliInfo' ? rows : personRows
      "
      v-model="formData"
    />
    <Navigation
      ref="navigation"
      class="mb-12"
      :navigation-item="navigationItem"
    />
    <RowLink :links="links" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import { demonstrationFieldApi } from "@/utils/api.js";
import RowLink from "@/components/select/rowLink.vue";
export default {
  components: {
    Form,
    Navigation,
    FooterButton,
    RowLink,
  },
  data() {
    return {
      loading: false,
      id: "",
      activeName: "",
      formData: {},
      isHideTerminalField: true,
      isHideBigGrowersField: true,
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    links() {
      if (this.activeName === "DemonstrationFieldShidanliInfo") {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=demonstrationField&id=${this.id}&tab=shidanli`,
          },
        ];
      } else {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=demonstrationField&id=${this.id}&tab=ziyou`,
          },
        ];
      }
    },
    rows() {
      return [
        {
          key: "modelFieldType",
          label: "示范田类型",
          type: "picker",
          required: true,
          dictType: "modelfield_type",
          defaultLabel: "modelFieldTypeName",
        },
        {
          key: "modelFieldName",
          label: "示范田名称",
          type: "input",
          required: true,
          gap: true,
        },
        // 所属业务主任不能修改，不需要传
        // {
        //   key: "所属业务主任",
        //   label: "所属业务主任",
        //   type: "picker",
        //   required: true,
        // },
        {
          key: "dealerId",
          label: "所属经销商",
          type: "picker",
          required: true,
          gap: true,
          dictType: "dealer",
          defaultLabel: "dealerName",
        },
        {
          key: "mobile",
          label: "手机",
          type: "input",
          inputType: "tel",
          required: true,
        },
        {
          key: "kjRegion",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "region",
          defaultLabel: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(
              this.formData.kjRegion
            );
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
            this.$set(this.formData, "towninfo", "");
            this.$set(this.formData, "village", "");
          },
        },
        {
          key: "kjTowninfo",
          label: "所属乡镇",
          type: "picker",
          required: true,
          dictType: "town",
          defaultLabel: "towninfo",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjVillage[0].fetchVillage(
              this.formData.kjTowninfo
            );
            this.$refs.form.$refs.kjVillage[0].reset();
          },
        },
        {
          key: "kjVillage",
          label: "所属村",
          type: "picker",
          required: true,
          gap: true,
          dictType: "village",
          defaultLabel: "village",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "plantCover",
          label: "示范作物",
          type: "picker",
          required: true,
          dictType: "plantSelect",
          defaultLabel: "plantCover",
        },
        {
          key: "plantNum",
          label: "种植亩数",
          type: "input",
          inputType: "number",
          unit: "亩",
          required: true,
        },
        {
          key: "plantMatch",
          label: "肥料配比",
          type: "input",
          required: true,
        },
        {
          key: "perNum",
          label: "每亩用量",
          type: "input",
          inputType: "number",
          unit: "亩",
          required: true,
          gap: true,
        },
        {
          key: "businessMan",
          label: "属地化业务员",
          type: "picker",
          dictType: "salesman",
          defaultLabel: "businessManName",
        },
        {
          key: "modelFieldStatus",
          label: "示范田状态",
          type: "radio",
          data: [
            { key: "0", value: "示范中" },
            { key: "1", value: "已结束" },
          ],
          required: true,
          gap: true,
        },
      ];
    },
    personRows() {
      return [
        {
          key: "modelFieldType",
          label: "示范田类型",
          type: "picker",
          required: true,
          dictType: "modelfield_type",
          defaultLabel: "modelFieldTypeName",
          handleChange: (value) => {
            // 重置所有信息
            this.isHideTerminalField = true;
            this.isHideBigGrowersField = true;
            this.$set(this.detail, "kjChannel", "");
            if (value === "2") {
              this.isHideTerminalField = false;
            }
            if (value === "3") {
              this.isHideBigGrowersField = false;
            }
          },
        },
        {
          key: "modelFieldName",
          label: "示范田名称",
          type: "input",
          required: true,
          gap: true,
        },
        {
          key: "dealerId",
          label: "所属经销商",
          type: "picker",
          required: true,
          dictType: "dealer",
          defaultLabel: "dealerName",
        },
        {
          key: "kjChannel",
          label: !this.isHideTerminalField ? "所属终端" : "种植大户名称",
          type: "picker",
          required: true,
          gap: true,
          dictType: !this.isHideTerminalField ? "terminal" : "grower",
          defaultLabel: "kjChannelName",
          hide: this.isHideTerminalField && this.isHideBigGrowersField,
        },
        {
          key: "mobile",
          label: "手机",
          type: "input",
          inputType: "tel",
          required: true,
        },
        {
          key: "kjRegion",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "region",
          defaultLabel: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(
              this.formData.kjRegion
            );
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
            this.$set(this.formData, "towninfo", "");
            this.$set(this.formData, "village", "");
          },
        },
        {
          key: "kjTowninfo",
          label: "所属乡镇",
          type: "picker",
          required: true,
          dictType: "town",
          defaultLabel: "towninfo",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjVillage[0].fetchVillage(
              this.formData.kjTowninfo
            );
            this.$refs.form.$refs.kjVillage[0].reset();
          },
        },
        {
          key: "kjVillage",
          label: "所属村",
          type: "picker",
          required: true,
          gap: true,
          dictType: "village",
          defaultLabel: "village",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "plantCover",
          label: "示范作物",
          type: "picker",
          required: true,
          dictType: "plantSelect",
          defaultLabel: "plantCover",
        },
        {
          key: "plantNum",
          label: "种植亩数",
          type: "input",
          inputType: "number",
          unit: "亩",
          required: true,
        },
        {
          key: "plantBrand",
          label: "示范产品",
          type: "input",
          required: true,
        },
        {
          key: "perNum",
          label: "每亩用量",
          type: "input",
          inputType: "number",
          unit: "亩",
          required: true,
          gap: true,
        },
        {
          key: "businessMan",
          label: "属地化业务员",
          type: "picker",
          dictType: "salesman",
          defaultLabel: "businessManName",
        },
        {
          key: "modelFieldStatus",
          label: "示范田状态",
          type: "radio",
          data: [
            { key: "0", value: "示范中" },
            { key: "1", value: "已结束" },
          ],
          required: true,
          gap: true,
        },
      ];
    },
  },
  watch: {
    detail() {
      this.formData = {
        ...this.formData,
        ...this.detail,
        dealerId: this.detail.kjDealer.dealerId,
        dealerName: this.detail.kjDealer.dealerName,
      };
      if (this.formData.kjRegion) {
        this.$refs.form.$refs.kjTowninfo[0].fetchTown(this.formData.kjRegion);
      }
      if (this.formData.kjTowninfo) {
        this.$refs.form.$refs.kjVillage[0].fetchVillage(
          this.formData.kjTowninfo
        );
      }
      if (this.formData.modelFieldType === "2") {
        this.isHideTerminalField = false;
      }
      if (this.formData.modelFieldType === "3") {
        this.isHideBigGrowersField = false;
      }
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
    this.isHideBigGrowersField = true;
    this.isHideTerminalField = true;
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        modelFieldName,
        modelFieldType,
        mobile,
        kjRegion,
        kjTowninfo,
        kjVillage,
        plantNum,
        plantMatch,
        perNum,
        modelFieldStatus,
        dealerId,
        plantBrand,
        plantCover,
        businessMan,
        kjChannel,
      } = this.formData;
      try {
        if (this.activeName === "DemonstrationFieldShidanliInfo") {
          await demonstrationFieldApi.save({
            id: this.id,
            plantCover: plantCover || "",
            kjChannel: kjChannel || "",
            kjChannelName: "",
            businessMan: businessMan || "",
            modelFieldName: modelFieldName || "",
            modelFieldType: modelFieldType || "",
            mobile: mobile || "",
            kjRegion: kjRegion || "",
            kjTowninfo: kjTowninfo || "",
            kjVillage: kjVillage || "",
            plantNum: plantNum || "",
            plantMatch: plantMatch || "",
            perNum: perNum || "",
            modelFieldStatus: modelFieldStatus || "",
            "kjDealer.dealerId": dealerId || "",
            position: this.$refs.navigation.value,
            coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          });
        } else {
          await demonstrationFieldApi.distSave({
            id: this.id,
            plantCover: plantCover || "",
            businessMan: businessMan || "",
            kjChannel: kjChannel || "",
            kjChannelName: "",
            modelFieldName: modelFieldName || "",
            modelFieldType: modelFieldType || "",
            mobile: mobile || "",
            kjRegion: kjRegion || "",
            kjTowninfo: kjTowninfo || "",
            kjVillage: kjVillage || "",
            plantNum: plantNum || "",
            plantBrand: plantBrand || "",
            plantMatch: plantMatch || "",
            perNum: perNum || "",
            modelFieldStatus: modelFieldStatus || "",
            "kjDealer.dealerId": dealerId || "",
            position: this.$refs.navigation.value,
            coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          });
        }
        this.$toast("保存成功");
        // this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
